import React, { Component } from "react";
import { Header } from "../../components/header";
import { Container, Row, Col, Form, FormControl, Button, Dropdown } from "react-bootstrap";
import { FirebaseProvider } from "../../providers/FirebaseProvider";


export class RSVP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      adults: 1,
      children: 0,
      event: 'Both',
      error: null
    };
  }

  handleNameChange = (e) => {
    let value = e.target.value;
    this.setState({ name: value });
  }

  handleAdultsChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ adults: value });
  }

  handleChildrenChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ children: value });
  }

  handleEventChange = (value) => {
    this.setState({ event: value, adults: value === 'Neither' ? 0 : this.state.adults, children: value === 'Neither' ? 0 : this.state.children });
  }

  handleSubmitClick = () => {
    if (this.state.name.length > 3) {
      FirebaseProvider.submitForm(this.state.name, this.state.adults, this.state.children, this.state.event).then(() => {
        this.props.history.push(`/formsuccess?going=${this.state.event !== 'Neither'}`);
      }).catch((err) => {
        this.setState({ error: 'There was an error submitting your answers, please try again or send us an email at contact@atteberywedding.com .' });
      });
    } else {
      this.setState({ error: 'Please fill out all of the fields, including at least 1 adult.' });
    }
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Header />

        <Container className='pb-4'>
          <div>
            <Row>
              <Col md xs='12'>
                <p style={{ fontSize: '50px', marginTop: '25px' }}>
                  RSVP
                </p>
                <p style={{ fontSize: '25px' }}>
                  We hope that you will be able to join us for a celebration of matrimony. Please fill out and submit the form below by August 26th!
                </p>
                <Form>
                  {this.state.error ? <Form.Label style={{ color: '#ff0000' }}>{this.state.error}</Form.Label> : null}
                  <Form.Group>
                    <Form.Label>Reservation Name</Form.Label>
                    <Form.Control type='text' placeholder='i.e. Mr. & Mrs. Attebery' value={this.state.name} onChange={this.handleNameChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Will you be attending the wedding and/or the reception?</Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle  style={{minWidth: '150px'}} id="dropdown-basic">
                        {this.state.event}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => this.handleEventChange('Wedding Ceremony')}>Wedding Ceremony</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleEventChange('Reception')}>Reception</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleEventChange('Both')}>Both</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleEventChange('Neither')}>Neither</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  {this.state.event !== 'Neither' ? <><Form.Group>
                    <Form.Label>Adults Attending (Age 13+)</Form.Label>
                    <Form.Control type='number' value={this.state.adults} onChange={this.handleAdultsChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Children Attending</Form.Label>
                    <Form.Control type='number' value={this.state.children} onChange={this.handleChildrenChange} />
                  </Form.Group></> : null}
                  <Button block onClick={this.handleSubmitClick}>Submit</Button>
                </Form>
              </Col>

              <Col md xs='12' className='pt-4 text-center'>
                <img style={{ maxWidth: '100%', maxHeight: '500px', borderRadius: '30px'}} src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/look_blackwhite.jpg?alt=media&token=f8fcfc37-a0dd-44ea-b5ad-691a9b6d7070' />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}