import React, { Component } from "react";
import { Header } from "../../components/header";
import { Container, Row, Col, Form, FormControl, Button, Dropdown } from "react-bootstrap";
import { FirebaseProvider } from "../../providers/FirebaseProvider";


export class BridalShower extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      people: 1,
      isComing: true,
      error: null
    };
  }

  handleNameChange = (e) => {
    let value = e.target.value;
    this.setState({ name: value });
  }

  handlePeopleChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ people: value });
  }

  handleSubmitClick = () => {
    if (this.state.name.length > 3) {
      FirebaseProvider.submitBridalShowerForm(this.state.name, this.state.isComing, this.state.people).then(() => {
        this.props.history.push(`/formsuccess?going=${this.state.isComing}`);
      }).catch((err) => {
        this.setState({ error: 'There was an error submitting your answers, please try again or send us an email at contact@atteberywedding.com .' });
      });
    } else {
      this.setState({ error: 'Please fill out all of the fields, including at least 1 person.' });
    }
  }

  handleComingChange = (value) => {
    this.setState({ isComing: value, people: value ? this.state.people : 0 });
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Header />

        <Container className='pb-4'>
          <div>
            <Row>
              <Col md xs='12'>
                <p style={{ fontSize: '50px', marginTop: '25px' }}>
                  Bridal Shower
                </p>
                <p style={{ fontSize: '25px' }}>
                  We hope that you will be able to join Erika for a celebration of engagement. Please fill out and submit the form below by August 8th!
                </p>
                <Form>
                  {this.state.error ? <Form.Label style={{ color: '#ff0000' }}>{this.state.error}</Form.Label> : null}
                  <Form.Group>
                    <Form.Label>Reservation Name</Form.Label>
                    <Form.Control type='text' placeholder='i.e. Erika Attebery' value={this.state.name} onChange={this.handleNameChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Will you be attending the bridal shower?</Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle  style={{minWidth: '150px'}} id="dropdown-basic">
                        {this.state.isComing ?  'Yes' : 'No'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => this.handleComingChange(true)}>Yes</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleComingChange(false)}>No</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  {this.state.isComing ? <Form.Group>
                    <Form.Label>People Attending</Form.Label>
                    <Form.Control type='number' value={this.state.people} onChange={this.handlePeopleChange} />
                  </Form.Group> : null}
                  <Button block onClick={this.handleSubmitClick}>Submit</Button>
                </Form>
              </Col>

              <Col md xs='12' className='pt-4 text-center'>
                <img style={{ maxWidth: '100%', maxHeight: '500px', borderRadius: '30px'}} src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/IMG_1251.jpg?alt=media&token=78e28dde-4153-4f9f-b798-e3089e46f751' />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}