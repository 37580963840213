import React, { Component } from "react";
import { Container, Row, Col, ListGroup, Button, FormControl, Form } from "react-bootstrap";
import { FirebaseProvider } from "../../providers/FirebaseProvider";

export class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bridalShower: false,
      results: {
        totalAdults: 0,
        totalBridalPeople: 0,
        totalChildren: 0,
        responses: [],
        bridalResponses: []
      }
    }

    this.getResults();
  }

  getResults = () => {
    FirebaseProvider.getAllResponses().then((result) => {
      this.setState({ results: result });
    });

    FirebaseProvider.getAllBridalResponses().then((result) => {
      let res = this.state.results;
      res.bridalResponses = result.responses;
      res.totalBridalPeople = result.totalPeople;
      this.setState({ results: res });
    });
  }

  handleRSVPChecked = (id) => {
    FirebaseProvider.markRSVPSeen(id).then(() => {
      let res = this.state.results;
      let rsvps = res.responses;
      rsvps.forEach(x => {
        if (x.id === id)
          x.seen = true;
      });
      res.responses = rsvps;

      this.setState({ results: res });
    }).catch(() => {
      alert('Failed to update record.');
    });
  }

  render() {
    console.log(this.state.results);
    if (this.state.bridalShower){
      return (
        <Container>
          <div>
            <Button className='my-3' onClick={() => this.setState({ bridalShower: false })}>Show Wedding RSVP Data</Button>
            <ListGroup>
              <ListGroup.Item>
                <Row>
                  <Col>NAME</Col>
                  <Col>PEOPLE</Col>
                  <Col>ATTENDING</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup>
              {this.state.results.bridalResponses.map(resp => {
                return (
                  <ListGroup.Item>
                    <Row>
                      <Col>{resp.name}</Col>
                      <Col>{resp.people}</Col>
                      <Col>{resp.isComing ? 'Yes' : 'No'}</Col>
                    </Row>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
            <span>Total Responses: {this.state.results.bridalResponses.length}</span><br />
            <span>Total People: {this.state.results.totalBridalPeople}</span>
          </div>
        </Container>
      );
    } else {
      return (
        <Container>
          <div>
          <Button className='my-3' onClick={() => this.setState({ bridalShower: true })}>Show Bridal Shower RSVP Data</Button>
            <ListGroup>
              <ListGroup.Item>
                <Row>
                  <Col>NAME</Col>
                  <Col>ADULTS</Col>
                  <Col>CHILDREN</Col>
                  <Col>EVENT(S)</Col>
                  <Col>SEEN</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup>
              {this.state.results.responses.map(resp => {
                return (
                  <ListGroup.Item key={resp.id}>
                    <Row>
                      <Col>{resp.name}</Col>
                      <Col>{resp.adults}</Col>
                      <Col>{resp.children}</Col>
                      <Col>{resp.event}</Col>
                      <Col><Form.Check disabled={resp.seen} checked={resp.seen} onChange={() => this.handleRSVPChecked(resp.id)} /></Col>
                    </Row>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
            <span>Total Responses: {this.state.results.responses.length}</span><br />
            <span>Attending Wedding Only: {this.state.results.totalWedding}</span><br />
            <span>Attending Reception Only: {this.state.results.totalReception}</span><br />
            <span>Attending Both Wedding & Reception: {this.state.results.totalBoth}</span><br />
            <span>Total Adults: {this.state.results.totalAdults}</span><br />
            <span>Total Children: {this.state.results.totalChildren}</span><br />
            <span>Total People: {this.state.results.totalAdults + this.state.results.totalChildren}</span>
          </div>
        </Container>
      );
    }
  }
}
