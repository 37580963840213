import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import { Home } from './pages/home/home';
import { Photos } from './pages/photos/photos';
import { Registry } from './pages/registry/registry';
import { RSVP } from './pages/rsvp/rsvp';
import { Accommodations } from './pages/accommodations/accommodations';
import * as firebase from 'firebase';
import CONFIG from './FirebaseConfig';
import { FormSuccess } from './pages/formsuccess/formsuccess';
import { Admin } from './pages/admin/admin';
import { BridalShower } from './pages/bridalshower/bridalshower';

export default class App extends Component {
  constructor(props){
    super(props);
    
    if (!firebase.apps.length)
      firebase.initializeApp(CONFIG);
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={(props) => <Home {...props} />} />

          <Route exact path="/pictures" component={(props) => <Photos {...props} />} />

          <Route exact path="/registry" component={(props) => <Registry {...props} />} />

          <Route exact path="/rsvp" component={(props) => <RSVP {...props} />} />

          <Route exact path="/formsuccess" component={(props) => <FormSuccess {...props} />} />

          <Route exact path="/accommodations" component={(props) => <Accommodations {...props} />} />

          <Route exact path="/bridalshower" component={(props) => <BridalShower {...props} />} />

          <Route exact path="/admin" component={(props) => <Admin {...props} />} />
          
        </Switch>
      </BrowserRouter>
    );
  }
}
