import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Header } from "../../components/header";

export class Accommodations extends Component {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Header />
        <Container>
          <div>
            <Row>
              <Col>
                <p style={{fontSize: '50px', marginTop: '25px'}}>
                  Accommodations
                </p>
                <p style={{fontSize: '25px'}}>
                  Due to little or no need, we have not reserved rooms anywhere. The closest hotel is the Super 7 in Tecumseh. For other options, you can view
                  this list of places to stay in Lincoln: <a href='https://www.tripadvisor.com/Hotels-g45667-Lincoln_Nebraska-Hotels.html'>Trip Advisor</a>.
                </p>
                <p style={{fontSize: '25px'}}>
                  Super 7<br/>
                  402 N 12th ST<br/>
                  Tecumseh, NE 68540 <br/>
                  (402) 335-3100 <br/>
                  <a target='_blank' href='https://www.google.com/maps/place/Super+7+Inn/@40.372412,-96.1806624,15z/data=!4m8!3m7!1s0x0:0xd3a065c7631110cb!5m2!4m1!1i2!8m2!3d40.372412!4d-96.1806624'>View Map</a>
                </p>
              </Col>

              <Col md xs='12' className='pt-4 text-center'>
                <img style={{ maxWidth: '100%', maxHeight: '500px', borderRadius: '30px'}} src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/kiss_forehead.jpg?alt=media&token=4de84884-9691-40c9-94af-37412c780d7e' />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}
