import React, { Component } from "react";
import { Container, Jumbotron, Row, Navbar, Nav } from "react-bootstrap";

export class Header extends Component {
    render() {
        return (
            <Navbar collapseOnSelect bg="light" expand="md" sticky="top">
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Brand style={{ fontSize: 22 }} href="/">Tristan & Erika</Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto" style={{ fontSize: 18 }}>
                        <Nav.Link href="/#ceremony">Ceremony</Nav.Link>
                        <Nav.Link href="/#reception">Reception</Nav.Link>
                        <Nav.Link href="/RSVP">RSVP</Nav.Link>
                        <Nav.Link href="/Registry">Registry</Nav.Link>
                        <Nav.Link href="/Accommodations">Accommodations</Nav.Link>
                        <Nav.Link href="/Pictures">Pictures</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
