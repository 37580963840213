const CONFIG = {
    apiKey: "AIzaSyBYLIOUCs5kv_ndieFn8tHbzyxvqifRRWs",
    authDomain: "wedding-site-98eb8.firebaseapp.com",
    databaseURL: "https://wedding-site-98eb8.firebaseio.com",
    projectId: "wedding-site-98eb8",
    storageBucket: "wedding-site-98eb8.appspot.com",
    messagingSenderId: "736611253995",
    appId: "1:736611253995:web:5742f4d843ba4c8dd7c8ae"
};

export default CONFIG;