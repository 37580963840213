import * as firebase from 'firebase';

export class FirebaseProvider {
    static submitForm(name, adults, children, event) {
        return firebase.firestore().collection('reservations').add({
            name: name,
            adults: adults,
            children: children,
            event: event
        });
    }

    static submitBridalShowerForm(name, isComing, people) {
        return firebase.firestore().collection('bridalshowerreservations').add({
            name: name,
            isComing: isComing,
            people: people
        });
    }

    static getAllResponses() {
        return firebase.firestore().collection('reservations').get().then((docs) => {
            let res = {
                totalAdults: 0,
                totalChildren: 0,
                totalBoth: 0,
                totalWedding: 0,
                totalReception: 0,
                responses: []
            };

            docs.forEach(doc => {
                let data = doc.data();

                switch (data.event) {
                    case 'Both':
                        res.totalBoth += data.adults + data.children;
                        break;
                    case 'Wedding Ceremony':
                        res.totalWedding += data.adults + data.children;
                        break;
                    case 'Reception':
                        res.totalReception += data.adults + data.children;
                        break;
                }

                if (data.event !== 'Neither'){
                    res.totalAdults += data.adults;
                    res.totalChildren += data.children;
                }
                
                res.responses.push({ id: doc.id, ...data });
            });

            return res;
        });
    }

    static markRSVPSeen(id) {
        return firebase.firestore().collection('reservations').doc(id).update({ seen: true });
    }

    static getAllBridalResponses() {
        return firebase.firestore().collection('bridalshowerreservations').get().then((docs) => {
            let res = {
                totalPeople: 0,
                responses: []
            };

            docs.forEach(doc => {
                let data = doc.data();

                res.totalPeople += data.people;
                res.responses.push(data);
            });

            return res;
        });
    }

    static uploadImages(fileDataList) {
        let promises = [];

        let count = 1;
        fileDataList.forEach(data => {
            promises.push(
                firebase.storage().ref('image-' + count + '-' + Date.now()).put(data, { contentType: data.contentType })
            );
            count++;
        });

        return Promise.all(promises);
    }
}