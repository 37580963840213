import React, { Component } from "react";
import { Container, Row, Col, Form, FormControl, Button } from "react-bootstrap";
import { FirebaseProvider } from "../../providers/FirebaseProvider";

export class FormSuccess extends Component {

  render() {
    let going = window.location.search.split('=')[1];
    return (
      <Container>
        <div>
          <Row>
            <Col>
              <p style={{fontSize: '50px', marginTop: '25px'}}>
                Success!
              </p>
              <p style={{fontSize: '25px'}}>
                Thank you for submitting your reservation. { going == 'true' ? 'We look forward to seeing you. 🥳' : 'Sorry we won\'t be seeing you.'}
              </p>
              <Button onClick={() => this.props.history.push('/')}>Home Page</Button>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
