import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Header } from "../../components/header";

export class Registry extends Component {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Header />
        <Container>
          <div>
            <Row>
              <Col md xs='12'>
                <p style={{fontSize: '50px', marginTop: '25px'}}>
                  Registry
                </p>
                <p style={{fontSize: '25px'}}>
                  Our online registries are linked below. For cash gifts or gifts not from the registry, please bring them to the ceremony or reception.
                  If sending a gift, please address it to:
                  <br/>
                  Tristan & Erika Attebery <br/>
                  2015 NW 53rd ST <br/>
                  Lincoln, Nebraska 68528
                </p>
                <p style={{fontSize: '25px'}}>
                  <a href='https://www.amazon.com/wedding/share/tristananderika'>Amazon Registry</a>
                </p>
                <p style={{fontSize: '25px'}}>
                  <a href='http://tgt.gifts/tristananderika'>Target Registry</a>
                </p>
              </Col>

              <Col md xs='12' className='pt-4 text-center'>
                <img style={{ maxWidth: '100%', maxHeight: '500px', borderRadius: '30px' }} src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/standing_knot.jpg?alt=media&token=efdd96ad-9fdb-4f0d-9a1b-8eb615254711' />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}
