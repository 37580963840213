import React, { Component } from "react";
import { Header } from "../../components/header";
import { Container, Row, Col, Button, Spinner, Image } from "react-bootstrap";
import { FirebaseProvider } from "../../providers/FirebaseProvider";

export class Photos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      fileRefs: [],
      loadingFileData: false,
      uploading: false,
      complete: false
    };
  }

  readFile = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.onload = (e) => {
        resolve(reader.result);
      }

      reader.readAsDataURL(file);
    });
  }

  handleFilesChanged = (e) => {
    let files = Array.from(e.target.files);
    let fileData = [];

    let promises = [];
    files.forEach(file => {
      promises.push(this.readFile(file));
    });

    Promise.all(promises).then((res) => {
      this.setState({ files: res, fileRefs: files, complete: false });
    });
  }

  handleUploadClick = () => {
    this.setState({ uploading: true });
    FirebaseProvider.uploadImages(this.state.fileRefs).then(() => {
      this.setState({ files: [], fileRefs: [], uploading: false, loadingFileData: false, complete: true });
    }).catch((err) => {
      console.log(err);
    });
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Header />
      
        <Container>
          <div>
            <input id="file-input" multiple type='file' onChange={this.handleFilesChanged} accept="image/*" className='d-none' />
            <Row>
              <Col md='6' xs='12'>
                <p style={{ fontSize: '50px', marginTop: '25px' }}>
                  Pictures
                </p>
                <p style={{fontSize: '25px' }}>
                  Please feel free to upload any and all pictures taken throughout our wedding festivities.
                  Whether the pictures are of the loving couple, a fun selfie, or just aesthetically pleasing, we would love to see them!
                </p>
                {this.state.files.length < 1 ? <Button onClick={() => document.getElementById('file-input').click()}>{this.state.loadingFileData ? <Spinner animation='border' as='span' size='sm' /> : 'Select Photos'}</Button> : <Button disabled={this.state.uploading} onClick={this.handleUploadClick}>Upload {this.state.files.length} Images</Button>}
                <br />
                {this.state.uploading ? <span style={{ fontWeight: 'bold' }}>Please Wait... <Spinner animation='border' as='span' size='sm' /></span> : null}
                {this.state.complete ? <span style={{ fontWeight: 'bold', color: 'green' }}>Done! Thank you for sharing!</span> : null}
                <Row>
                  {this.state.files.map(data => {
                    return (
                      <Col xs='6' className='p-3'>
                        <Image src={data} rounded fluid />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col md='6' xs='12' className='pt-4 text-center'>
                <img style={{ maxWidth: '100%', maxHeight: '500px', borderRadius: '30px' }} src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/silly.jpeg?alt=media&token=6931736a-c896-4b9c-bebd-d47ccd41c05c' />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}
