import React, { Component } from "react";
import { Container, Jumbotron, Row, Carousel, Col, Image, Button, Alert } from "react-bootstrap";
import { Section } from "./section";
import { Header } from "../../components/header";
import image from '../../assets/IMG_2617.JPG';
import './home.css';
import ScrollableAnchor from "react-scrollable-anchor";

export class Home extends Component {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Header />
        <Alert className='m-0 text-center' variant='danger'>
          The wedding ceremony has been moved. It is now located at Hartmans in Tecumseh at 5:00 PM. View it on the map <a href='https://www.google.com/maps/place/Hartman+Cattle+Co/@40.4235064,-96.2545074,13z/data=!3m1!4b1!4m5!3m4!1s0x8795ce9bdd7e7879:0xdd1af0e79933231b!8m2!3d40.4234463!4d-96.2194878'>here</a>.
        </Alert>
        <Carousel>
          {/*Home Slide One*/}
          <Carousel.Item>
            <div className='d-block w-100' style={{ height: '500px', textAlign: 'center' }}>
              <div
                className="d-block carousel-back"
                style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/Sitting.jpg?alt=media&token=68d6b6e2-da71-447d-bef2-c87ce714c178)" }}
                alt="First slide"
              />
              <img
                className='carousel-img'
                src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/Sitting.jpg?alt=media&token=68d6b6e2-da71-447d-bef2-c87ce714c178'
                height={500}
                alt="First slide"
              />
            </div>
            <Carousel.Caption>
              <p style={{ fontSize: '6vw' }}>We're Engaged!</p>
              <p style={{ fontSize: '4vw' }}>Tying the knot September 26<sup>th</sup>, 2020</p>
            </Carousel.Caption>
          </Carousel.Item>

          {/*Home Slide Two*/}
          <Carousel.Item>
            <div className='d-block w-100' style={{ height: '500px', textAlign: 'center' }}>
              <div
                className="d-block carousel-back"
                style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/Standing.jpg?alt=media&token=795d0c78-0ef5-49cf-9049-a1f2d52f5141)" }}
                alt="First slide"
              />
              <img
                className='carousel-img'
                src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/Standing.jpg?alt=media&token=795d0c78-0ef5-49cf-9049-a1f2d52f5141'
                height={500}
                alt="First slide"
              />
            </div>
            <Carousel.Caption>
              <p style={{ fontSize: '6vw' }}>We're Engaged!</p>
              <p style={{ fontSize: '4vw' }}>Tying the knot September 26<sup>th</sup>, 2020</p>
            </Carousel.Caption>
          </Carousel.Item>

          {/*Home Slide Three*/}
          <Carousel.Item>
            <div className='d-block w-100' style={{ height: '500px', textAlign: 'center' }}>
              <div
                className="d-block carousel-back"
                style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/flower.jpg?alt=media&token=ed46d779-6359-4463-8239-594eb4ecfea2)" }}
                alt="First slide"
              />
              <img
                className='carousel-img'
                src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/flower.jpg?alt=media&token=ed46d779-6359-4463-8239-594eb4ecfea2'
                height={500}
                alt="First slide"
              />
            </div>
            <Carousel.Caption>
              <p style={{ fontSize: '6vw' }}>We're Engaged!</p>
              <p style={{ fontSize: '4vw' }}>Tying the knot September 26<sup>th</sup>, 2020</p>
            </Carousel.Caption>
          </Carousel.Item>

          {/*Home Slide Four*/}
          <Carousel.Item>
            <div className='d-block w-100' style={{ height: '500px', textAlign: 'center' }}>
              <div
                className="d-block carousel-back"
                style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/forehead%20kiss.jpg?alt=media&token=325fd9e4-3956-4950-8b93-51403dde9c6a)" }}
                alt="First slide"
              />
              <img
                className='carousel-img'
                src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/forehead%20kiss.jpg?alt=media&token=325fd9e4-3956-4950-8b93-51403dde9c6a'
                height={500}
                alt="First slide"
              />
            </div>
            <Carousel.Caption>
              <p style={{ fontSize: '6vw' }}>We're Engaged!</p>
              <p style={{ fontSize: '4vw' }}>Tying the knot September 26<sup>th</sup>, 2020</p>
            </Carousel.Caption>
          </Carousel.Item>

          {/*Home Slide Five*/}
          <Carousel.Item>
            <div className='d-block w-100' style={{ height: '500px', textAlign: 'center' }}>
              <div
                className="d-block carousel-back"
                style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/dip.jpg?alt=media&token=93a016af-0163-4daf-9366-bba6608356f6)" }}
                alt="First slide"
              />
              <img
                className='carousel-img'
                src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/dip.jpg?alt=media&token=93a016af-0163-4daf-9366-bba6608356f6'
                height={500}
                alt="First slide"
              />
            </div>
            <Carousel.Caption>
              <p style={{ fontSize: '6vw' }}>We're Engaged!</p>
              <p style={{ fontSize: '4vw' }}>Tying the knot September 26<sup>th</sup>, 2020</p>
            </Carousel.Caption>
          </Carousel.Item>

          {/*Home Slide Six*/}
          <Carousel.Item>
            <div className='d-block w-100' style={{ height: '500px', textAlign: 'center' }}>
              <div
                className="d-block carousel-back"
                style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/crossed%20arms.jpg?alt=media&token=8f91854b-c530-4c49-abf0-f514dcb0ed48)" }}
                alt="First slide"
              />
              <img
                className='carousel-img'
                src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/crossed%20arms.jpg?alt=media&token=8f91854b-c530-4c49-abf0-f514dcb0ed48'
                height={500}
                alt="First slide"
              />
            </div>
            <Carousel.Caption>
              <p style={{ fontSize: '6vw' }}>We're Engaged!</p>
              <p style={{ fontSize: '4vw' }}>Tying the knot September 26<sup>th</sup>, 2020</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        {/*bridal shower to ceremony page break*/}
        <ScrollableAnchor id={'ceremony'}>
          <div style={{ color: '#000000', height: '50px' }}></div>
        </ScrollableAnchor>

        <Row className='flex-row-reverse align-items-center'>
          <Col xs='12' md className='text-center'>
            {/*ceremony page picture*/}
            <img style={{ borderRadius: '50%', width: '100%', maxWidth: '500px' }} src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/twirl_square.jpg?alt=media&token=f13d2938-609a-4523-98c0-ee97701f1e8d' />
          </Col>

          <Col style={{ flexDirection: 'row' }} xs='12' md>
            {/*ceremony page text*/}
            <div style={{ textAlign: 'center', marginTop: '25px' }}>
              <p style={{ fontSize: '50px' }}>The Ceremony</p>
              <p style={{ fontSize: '25px' }}>
                5:00 PM <br />
                Hartmans<br />
                61875 NE-41 <br />
                Tecumseh, NE 68450 <br />
                <a href='https://www.google.com/maps/place/Hartman+Cattle+Co/@40.4235064,-96.2545074,13z/data=!3m1!4b1!4m5!3m4!1s0x8795ce9bdd7e7879:0xdd1af0e79933231b!8m2!3d40.4234463!4d-96.2194878'>View Map</a><br/>
                <Button onClick={() => this.props.history.push('/RSVP')}>RSVP To Wedding</Button>
              </p>
            </div>
          </Col>
        </Row>

        {/*ceremony to reception page break*/}
        <ScrollableAnchor id={'reception'}>
          <div style={{ color: '#000000', height: '50px' }}></div>
        </ScrollableAnchor>

        <Row className='align-items-center'>
          <Col xs='12' md className='text-center'>
            {/*reception page picture*/}
            <img style={{ borderRadius: '50%', width: '100%', maxWidth: '500px' }} src='https://firebasestorage.googleapis.com/v0/b/wedding-site-98eb8.appspot.com/o/IMG_9133.jpg?alt=media&token=5b8d442f-735e-48d3-9342-143fb213e845' />
          </Col>

          <Col xs='12' md>
            {/*reception page text*/}
            <div style={{ textAlign: 'center', marginTop: '25px' }}>
              <p style={{ fontSize: '50px' }}>The Reception</p>
              <p style={{ fontSize: '25px' }}>
                Happy Hour: 6:00 PM - 7:00 PM <br />
                Dinner & Celebration: 7:00 PM - 11:00 PM <br />
                Hartman Cattle Co. <br />
                61875 NE-41 <br />
                Tecumseh, NE 68450 <br />
                <a href='https://www.google.com/maps/place/Hartman+Cattle+Co/@40.4235064,-96.2545074,13z/data=!3m1!4b1!4m5!3m4!1s0x8795ce9bdd7e7879:0xdd1af0e79933231b!8m2!3d40.4234463!4d-96.2194878'>View Map</a><br/>
                <Button onClick={() => this.props.history.push('/RSVP')}>RSVP To Reception</Button>
              </p>
            </div>
          </Col>
        </Row>

        <div style={{ color: '#000000', height: '50px' }}>
          {/*page break*/}
        </div>
      </div>
    );
  }
}
