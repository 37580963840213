import React, { Component } from "react";
import { Container, Jumbotron } from "react-bootstrap";

export class Section extends Component {
  render() {
    return (
      <div className={this.props.className} style={{ height: "200px", width: "100%" }}>
        {this.props.children}
      </div>
    );
  }
}
